import $ from 'jquery';

window.eb.isConfirmationKey = '1';

// Used in 2 places
window.getTransactionValue = () => {
  document.getElementById('hidden_transactionOTP').value = 'cotp';
};

// replace all calls with window.location
window.processResponseEBPlus_Redirect = (redirectPath) => {
  // redirect to new portlet
  window.location = redirectPath;
};

// Used in 1 place
window.processResponseEB_CIMG = (
  response,
  namespace,
  link,
  _isFirst,
  operationId,
) => {
  if (response.errorCode === '0' && response.operationData !== '') {
    const { operationData } = response;

    // don´t have content inside rightSide
    if (operationData != null) {
      const responsePhoto = `data:image/png;base64, ${operationData.CIMG_O_0008}`;
      const previewWidth = $('#img_mov').closest('.right-side').width();
      const previewHeight = 311;
      const img = new Image();
      img.src = responsePhoto;

      img.onload = () => {
        let src = responsePhoto;

        if (img.width > previewWidth || img.height > previewHeight) {
          src = window.transformImgToCanvas(img, previewWidth, previewHeight);
        }

        $('#img_mov').attr('src', src);
      };

      $('#img_mov')
        .css({ cursor: 'pointer' })
        .on('click', () => {
          window.eb.dialog.custom(
            {},
            `<div class="text-center image-container"><img src="${responsePhoto}"/></div>`,
          );
        });
    }
  } else {
    window.eb.errorHandler({
      response,
      link,
      _isFirst,
      operationId,
    });
  }
};

/**
 * get an image and creates a tumbnail for that image
 */
window.transformImgToCanvas = (img, previewWidth, previewHeight) => {
  const { width } = img;
  const { height } = img;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = previewWidth;
  canvas.height = previewHeight;

  ctx.drawImage(
    img,
    width > height ? (width - height) / 2 : 0,
    height > width ? (height - width) / 2 : 0,
    width > height ? height : width,
    width > height ? height : width,
    0,
    0,
    previewWidth,
    previewHeight,
  );

  return canvas.toDataURL();
};

// Used in 3 places
window.backKeyLayout = (operationId, numberMaxDigit, transSecondary) => {
  if (nameIDEBPLus) {
    operationId = nameIDEBPLus;
  }

  const isExpiration = nameIDEBPLus === 'expiration';
  const formElement = isExpiration
    ? $('#ebPlusIDformIDexpiration')
    : $('#ebPlusIDformID');
  const keyTransSecondary = $(`#ebPlusID${transSecondary}key`);
  let keyElement = $(`#ebPlusID${operationId}key`);
  const sucessTransSecondary = $(`#ebPlusID${transSecondary}3`);
  let sucessElement = $(`#ebPlusID${operationId}3`);
  const otpElement = isExpiration ? $('#optEBplusexpiration') : $('#optEBplus');
  const hidden_isOTP =
    $('#hidden_isOTP').val() != '' ? $('#hidden_isOTP').val() : 'false';
  const clearOTP = isExpiration ? $('#codeOPTexpiration') : $('#codeOPT');
  const softTokenElement = $('#softTokenEBplus');
  const hidden_isSoftToken = $('#hidden_isSoftToken').val() === 'S';

  if (operationId === 'tats') {
    operationId = nameIDEBPLus || '';
    keyElement = $(`#ebPlusID${nameIDEBPLus}key`);
    sucessElement = $(`#ebPlusID${nameIDEBPLus}3`);
  }

  // Exist secondary Transactions
  if (keyElement.length == 0 || transSecondary === 'aopp' || transSecondary === 'dopr' || transSecondary === 'aopr') {
    keyElement = keyTransSecondary;
    sucessElement = sucessTransSecondary;
    operationId = transSecondary;
  }
  // have confirmation key or card key, sucess and form element
  if (keyElement && formElement && (sucessElement != '' || isExpiration)) {
    formElement.show();
    $('#errorSystemEBC').hide();
    !isExpiration ? sucessElement.hide() : '';
    $('#backFormInitialBtn').hide();

    // set value of status --> D
    $('#statusD').val('D');

    // If is confirmation key method
    if (methodKeyConfirmation === window.eb.isConfirmationKey) {
      // clean and required confirmation key field
      for (let count = 1; count <= numberMaxDigit; count++) {
        const clearKey = $(`#FieldValueConfirmation${operationId}${count}`);

        // Exist key and OTP - status [Q or U]
        if (hidden_isOTP != 'true') {
          // Confirmation Key elements
          if (clearKey && clearKey.val() != '') {
            keyElement.show();
            clearKey.val('');

            // OTP elements
            if (clearOTP) {
              otpElement.hide();
              clearOTP.val('');
            }

            softTokenElement.hide();

            // OTP elements
          } else if (clearOTP && !hidden_isSoftToken) {
            otpElement.show();
            clearOTP.val('');

            softTokenElement.hide();
          } else if (hidden_isSoftToken) {
            softTokenElement.show();
          }

          // Exist OTP - status [O]
        } else {
          clearOTP.val('');
          otpElement.show();
        }
      }

      // if is card key method
    } else {
      // Exist otp, clean and added required field
      if (clearOTP) {
        clearOTP.val('');
      }

      // Exist card and OTP - status [R or V]
      if (hidden_isOTP != 'true') {
        if (hidden_isSoftToken) {
          softTokenElement.show();
        } else {
          const matrixButtonRow = `#matrixButtonRow${operationId}`;
          keyElement.show();

          // Resets the users progress on the coordinate table, starts from coordinate one.
          eb.coordinatesCard.cardKeyClearTable(operationId);

          $(`#matrixButtonRow${operationId} .btnConfirmKey`).hide();
          $(`#matrixButtonRow${operationId} #btnQ-step2-key`).hide();
          $(`#matrixButtonRow${operationId} #btnU-step2-key`).hide();
        }

        // Exist OTP - status [O]
      } else if (hidden_isOTP == 'true') {
        otpElement.show();
      }
    }
  } else if (clearOTP) {
    formElement.show();
    $('#errorSystemEBC').hide();
    $('#backFormInitialBtn').hide();
    otpElement.show();
    clearOTP.val('');
  }

  if ($('.expiration-dialog').length) {
    $('.expiration-dialog').fadeIn(250);
  }
}

// To pass to error handler callbacks
window.callAjaxSucess = (operationId) => {
  if (typeof breadcrumb !== 'undefined') {
    try {
      window.breadcrumb.removeTitleLevel();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info(e);
    }
  }

  const formElement = !$('.EB_ExpirationDialog').length
    ? $('#ebPlusIDformID').validate()
    : $(expirationDialog).find('form').validate();

  // Validation if form is Valid
  if (formElement.form()) {
    // Remove element of resume form
    $(`.resumeContainer${operationId}`).removeClass(
      'eb-row-full has-padding has-background',
    );
    $(`.resumeContainer${operationId}`).height(0);

    if (document.getElementById(`ebPlusID${operationId}2`)) {
      document.getElementById(`ebPlusID${operationId}2`).style.display = 'none';
    }

    if ($(`#ebPlusID${operationId}key`).length) {
      $(`#ebPlusID${operationId}key`).hide();
    }

    if ($('#optEBplus').length) {
      $('#optEBplus').hide();
    }

    if ($('#softTokenEBplus').length) {
      $('#softTokenEBplus').hide();
    }

    if ($('.EB_ExpirationDialog').length != 0) saveValuesInput(operationId);

    eb.post(simGenericoLinkOTP, {
      operationId: 'mwcotp',
      _isFirst: 'isBackgroundTransactionW',
    }).then(window.processResponseEBPlus_cotp);
  }
};


/**
 * Get value of confirmation input
 *
 */
window.saveValuesInput = (nameTrans) => {
  for (let i = 1; i <= window.numberMaxDigit; i++) {
    $('#FieldValueConfirmation' + nameTrans + i).attr('required', 'true');
    $('#FieldValConfirmation' + nameTrans + i).val($('#FieldValueConfirmation' + nameTrans + i).val());
  }
};

/**
 *
 * Icons onclick redirect to movements
 *
 * @param {String} accountRedirect - account
 * @param {String} redirectOperationID - link to redire
 *
 */
window.redirectByAccountsEBP = (
  accountRedirect,
  redirectOperationID,
  accountNumber11,
) => {
  // add zero left of account number
  let countValues = '';
  const maxNumberAccount = accountNumber11 ? 11 : 14;
  const jsonData = {};
  const transactionCode = redirectOperationID; // Get transaction code of operation
  const inputField = $('#saveDataEB');

  for (
    let count = 0;
    count < maxNumberAccount - accountRedirect.length;
    /* eslint-disable-next-line */
    count++
  ) {
    countValues += '0';
  }

  if (accountRedirect != null) {
    /* eslint-disable-next-line */
    accountRedirect = countValues + accountRedirect;
  } else {
    /* eslint-disable-next-line */
    accountRedirect = '';
  }

  jsonData[`isRecall${transactionCode}`] = 'true';
  jsonData[`EB_accountStartup_${transactionCode}`] = accountRedirect;

  // verify if there is a field in this jsp
  if (inputField) {
    // initialize with received value
    const myJSONText = JSON.stringify(jsonData);
    inputField.val(myJSONText);

    window.eb.predefinedAjax.saveData(redirectOperationID);
  }
};

// To pass into a verifications module
window.ValidationOfTransactionSecundary = (operationId, isOptionMenu) => {
  /* eslint-disable-next-line */
  isOptionMenu = isOptionMenu || false; // default ois secondary transaction
  let result = false;
  if (isOptionMenu) {
    const parsedResponse = JSON.parse(window.jsonMenuOption);
    result = parsedResponse.operationData.transactions.includes(
      operationId.toUpperCase(),
    );
  } else if (
    /* eslint-disable-next-line */
    window._userValidTransactionsAll.indexOf(`'${operationId}'`) != -1
  ) {
    result = true;
  }

  return result;
};

// Used only in epse and dope
window.ajaxCallCDTPS = (
  response,
  transactionParameters,
  isStep2,
  operationID,
  processResponse,
  element,
) => {
  const callBackParameterCDPTS = {
    responseInitial: response,
    isStep2,
    table: element || '',
  };

  const parametersCDTPS = {
    typeTransaction: 'CDTPS',
    parameters: transactionParameters,
  };

  window.eb
    .post('/receiveParameters', {
      data: parametersCDTPS,
      _isFirst: 'isBackgroundTrans',
    })
    .then((responseCDTPS) => {
      window[`processResponseEBPlus_${processResponse}${operationID}`](
        responseCDTPS,
        'isBackgroundTrans',
        callBackParameterCDPTS,
      );
    });
};

// Used in 1 place
window.gotoStart = (trans) => {
  if (trans) {
    window.location = `${trans}`;
  }
};

// To pass to the verifications module
window.hypenEmptyField = (val, isDate) => {
  if (!val || val === '') {
    if (isDate) {
      return '--/--/--';
    }
    return '-';
  }
  return val;
};

window.hideProductHeader = () => {
  $('#cprd-product-header, #ccap-product-header, #card-header').hide();
};

window.showProductHeader = () => {
  $('#cprd-product-header, #ccap-product-header, #card-header').show();
};

// Only used in this file
window.showTransactionDetailHeader = () => {
  if ($('.transaction-header-details').length) {
    $('.transaction-header-details').show();
  }
};

// used in various files
window.resetFormInputs = (fields) => {
  const fieldsToClean = fields || [];

  fieldsToClean.forEach((element) => {
    const $element = $(`#${element}`);
    const tagName = $element.prop('tagName');

    if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
      $element.val(null);
    } else if (tagName === 'SELECT') {
      $element.find('option').first().attr('selected', 'selected');
    }

    $element.removeClass('error').next('label.error').remove();
  });
};

// To pass to a module
window.showSucessDiv = (operationId) => {
  const element = `ebPlusID${operationId}`;

  if (document.getElementById(`${element}1`)) {
    document.getElementById(`${element}1`).style.display = 'none';
  }

  if (document.getElementById(`${element}2`)) {
    document.getElementById(`${element}2`).style.display = 'none';
  }

  if (document.getElementById(`${element}3`)) {
    document.getElementById(`${element}3`).style.display = 'block';
  }
};

// cards
window.cardComponent = (currentTransactionCode, nameID) => {
  if (
    $('#ccar-details-back-btn') &&
    $('#TableDetailsCCAR').css('display') === 'block'
  ) {
    if (!currentTransactionCode || nameID === 'ccem' || nameID === 'acar') {
      $('#ccar-details-back-btn').css('display', 'inline-block');
      $('#ccar-cards-detail-buttons').css('display', 'flex');
      $('#grid_ctcd').show();
    }
  }
};

// only used in cards
window.removeAllWidgetRequireds = (requiredInputs) => {
  $.each(requiredInputs, (index, element) => {
    element.forEach((elm) => {
      $(`#${elm}`).removeAttr('required');
    });
  });
};

// Used in cards
window.setRequiredInputs = (requiredInputs, transaction) => {
  const inputsToSetRequired = requiredInputs[transaction] || [];
  window.removeAllWidgetRequireds(requiredInputs);

  inputsToSetRequired.forEach((element) => {
    $(`#${element}`).prop('required', true);
  });
};


// Used in Beneficiaries and Cope Lote

function handleTooltipMessages() {
  $('.success-table .row-data').tooltip({
    track: true,
    classes: {
      'ui-tooltip': 'tooltip-pending-operation'
    }
  });
};

window.redirectIframe = (link) => {
  const IsInIframe = parent !== window;
  let ParentUrl = null;

  if (IsInIframe) {
    ParentUrl = document.referrer;
  }
  window.top.location = ParentUrl + link;
};
