import $ from 'jquery';

window.errorConfirmationKey = false; // if number of requests is > of confirmation key --> ERROR

// clean conf key logic
window.backFormConfirmationKey = (nameID) => {
  if ($('.no-confirmation-header-back').length === 0) {
    if ($('#ebPlusIDcdpe1').parent().is(':visible')) {
      $('#tabs-personalization').addClass('eb-row-restricted');
    }
  }

  window.cleanConfirmationKey(nameID);
  const resumeExist = document.getElementById(`ebPlusID${nameID}2`);
  const elementExist = document.getElementById(`ebPlusID${nameID}1`);
  const keyExist = document.getElementById(`ebPlusID${nameID}key`);
  const secundaryElementExist = document.getElementById(
    `ebPlusID${window.nameIDEBPLus}1`,
  );

  // Remove element of resume form
  $('#confirmationContainer').removeClass('confirmation-layout');
  $('#confirmationContainer').height(0);

  // Personalization transactions require their tabs to be shown
  $('#tabs-personalization').show();

  // shows button only when no expiration
  if ($('.EB_ExpirationDialog').length > 0) {
    $(`#ebPlusID${window.nameTrans}1`).show();
    $(`#ebPlusID${window.nameTrans}1 .btnApply`).show();
    window.cleanConfirmationKey('expiration');
  }

  if (resumeExist && elementExist) {
    if (window.breadcrumb && window.breadcrumb.addTitle)
      window.breadcrumb.addTitle(window.eb.labels.breadcrumb.breadcrumbResume);
    window.showProductHeader();

    resumeExist.style.display = '';

    // Added element of resume form
    $(`.resumeContainer${nameID}`).addClass(
      'eb-row-full has-padding has-background',
    );

    keyExist.style.display = 'none';
  } else if (elementExist) {
    elementExist.style.display = '';
    keyExist.style.display = 'none';
    $('.transaction-header-details').show();
  } else if (secundaryElementExist) {
    secundaryElementExist.style.display = '';
    keyExist.style.display = 'none';
    $('.transaction-header-details').show();
  }
  window.cleanConfirmationKey(nameID);
};

// clean conf key logic
window.cleanConfirmationKey = (nameID) => {
  const keyExist = $(`#ebPlusID${nameID}key`);
  const variable =
    typeof window.variable_CPAR_O_0007 !== 'undefined'
      ? window.variable_CPAR_O_0007
      : 0;
  if (
    keyExist &&
    window.methodKeyConfirmation === window.eb.isConfirmationKey
  ) {
    // Confirmation Key
    if (variable != null && variable !== 0) {
      /* eslint-disable-next-line */
      for (let i = 1; i <= variable; i++) {
        const clearKey = $(`#FieldValueConfirmation${nameID}${i}`);
        clearKey.val('');
      }
    }
    $(`#ebPlusID${nameID}key`).hide();
  }
};

// Confirmation key logic
window.formKeyValidate = (
  nameID,
  _btnConfirmBackKey,
  elementExist,
  sucessExist,
  isFirst,
  operationID,
) => {
  if (sucessExist) {
    document.getElementById(`ebPlusID${nameID}3`).style.display = '';
    window.setFocusFirstInputKey();
  } else if (elementExist) {
    document.getElementById(`ebPlusID${nameID}1`).style.display = '';
  } else {
    document.getElementById(`ebPlusID${window.nameIDEBPLus}1`).style.display =
      '';
  }

  window.eb.post(window.simGenericoLink, {
    operationId: operationID,
    _isFirst: isFirst,
  });

  if (_btnConfirmBackKey) {
    /* eslint-disable-next-line */
    _btnConfirmBackKey.style.display = '';
  }
};

// Confirmation key logic
window.formKeyNotValidate = (keyExist, nameID) => {
  if (keyExist) {
    document.getElementById(`ebPlusID${nameID}key`).style.display = '';
    window.setFocusFirstInputKey();
  }
};

// To analyse with the confirmationkey logic
window.processResponseEBPlus_ConfirmationKey = (response, nameID) => {
  const digitsKeys = response.validation
    ? JSON.parse(response.validation.values)
    : [];
  const typeConfirmationMethod = response.validation
    ? response.validation.type
    : '';

  if (digitsKeys) {
    // get positions
    const arrayAllPosition = digitsKeys;
    const subscript = $('#subscript').val();
    const subscriptSplit = subscript.split(',');

    // Method is Key Confirmation
    if (typeConfirmationMethod === window.eb.isConfirmationKey) {
      if (arrayAllPosition[0] === -2) {
        // if number of requests is > of confirmation key --> ERROR
        window.errorConfirmationKey = true;
      } else {
        // set labels of position with random numbers - get Java
        for (
          let varCountPositonHidden = 1, j = 0;
          /* eslint-disable-next-line */
          varCountPositonHidden < window.variable_CPAR_O_0007,
          j < arrayAllPosition.length;
          /* eslint-disable-next-line */
          varCountPositonHidden++, j++
        ) {
          if (
            document.getElementById(
              `lblPosition${nameID}${varCountPositonHidden}`,
            ) != null
          ) {
            if (subscriptSplit[0] !== '&#8226') {
              document.getElementById(
                `lblPosition${nameID}${varCountPositonHidden}`,
              ).innerHTML = `${arrayAllPosition[j]}<span class='subscript'>${
                subscriptSplit[arrayAllPosition[j] - 1]
                }</span>`;
            } else {
              document.getElementById(
                `lblPosition${nameID}${varCountPositonHidden}`,
              ).innerHTML =
                arrayAllPosition[j] + subscriptSplit[arrayAllPosition[j] - 1];
            }
          }
        }
      }

      // Method is Coordinate card
    } else {
      const rows = $('#rows').val();
      const cols = $('#cols').val();
      const positionsPerCell = $('#positions').val();
      const matrixButtonRow = `#matrixButtonRow${nameID}`;

      // Creates a table that looks and acts like a coordinate card
      window.eb.coordinatesCard.createCoordinateCard(
        rows,
        cols,
        'cardKey',
        positionsPerCell,
        nameID,
      );

      // Draws inputs in each coordinate cell, regarding the "P" parameter in each coordinate object.
      window.eb.coordinatesCard.drawInputCoordinates(
        positionsPerCell,
        digitsKeys,
        nameID,
      );

      // Guides the user to fill out the desired postitions given for each cell
      window.eb.coordinatesCard.fillCoordinatePositions(
        positionsPerCell,
        nameID,
      );

      $('.cardKeyReset').on('click', () => {
        // Resets the users progress on the coordinate table, starts from coordinate one.
        window.eb.coordinatesCard.cardKeyClearTable(nameID);

        $(`${matrixButtonRow} .btnConfirmKey`).hide();
        $(`${matrixButtonRow} #btnQ-step2-key`).hide();
        $(`${matrixButtonRow} #btnU-step2-key`).hide();
      });

      if ($('.EB_ExpirationDialog-dialog').length) {
        $('#expiration-confirmation').append($(`#ebPlusID${nameID}key`));
        $('#ebPlusIDexpiration1').hide();
        $('#expiration-confirmation').show();
        if ($('.expiration-dialog').length) {
          const inputs = $(`#cardKeyContainer${nameID} .coordCellInput input`);
          if (inputs.length > 0) inputs[0].trigger('focus');
          $('.expiration-dialog').fadeIn(250);
        }
      }
    }
  }
};

// Pass into a conf key module
window.getValuesOfConfirmation = (operationId, response, showResume) => {
  const formElement =
    $(`#ebPlusID${operationId}1`).length > 0
      ? $(`#ebPlusID${operationId}1`)
      : $(`#ebPlusID${window.nameIDEBPLus}1`);
  const resumeElement =
    $(`#ebPlusID${operationId}2`).length > 0
      ? $(`#ebPlusID${operationId}2`)
      : $(`#ebPlusID${window.nameIDEBPLus}2`);

  document.getElementById('hidden_currentTransaction').value = operationId;
  document.getElementById('hidden_coreLogKey').value = response.coreLogKey;

  // Create resume with expenses
  if (resumeElement && $('.pending-operation-resume').length === 0) {
    window.createdResumetransfer(`${operationId}2`, operationId);
  }

  if (resumeElement && showResume) {
    // hide form and show resume page
    $(formElement).hide();
    $(resumeElement).show();
  }

  // Hide pending operation resume
  if ($('.pending-operation-resume').length)
    $('.pending-operation-resume').hide();
};

/**
 *@description - sets focus on the first input auth key
 */
window.setFocusFirstInputKey = () => {
  $(function () {
    const inputConfirmation = $('.inputConfirmationKey');
    if (inputConfirmation[0]) {
      inputConfirmation[0].trigger('focus');
    }
  });
};
