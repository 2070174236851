/* eslint-disable */
(function ($) {
  $.pwstrength = function (password) {
    let score = 0;
    const { length } = password;
    let upperCase;
    let lowerCase;
    let digits;
    let nonAlpha;

    if (length < 5) score += 0;
    else if (length < 8) score += 5;
    else if (length < 16) score += 10;
    else score += 15;

    lowerCase = password.match(/[a-z]/g);
    if (lowerCase) score += 1;

    upperCase = password.match(/[A-Z]/g);
    if (upperCase) score += 5;

    if (upperCase && lowerCase) score += 2;

    digits = password.match(/\d/g);
    if (digits && digits.length > 1) score += 5;

    nonAlpha = password.match(/\W/g);
    if (nonAlpha) score += nonAlpha.length > 1 ? 15 : 10;

    if (upperCase && lowerCase && digits && nonAlpha) score += 15;

    if (password.match(/\s/)) score += 10;

    if (score < 15) return 0;
    if (score < 20) return 1;
    if (score < 35) return 2;
    if (score < 50) return 3;
    return 4;
  };

  function updateIndicator(event) {
    const strength = $.pwstrength($(this).val());
    const options = event.data;
    let klass;
    klass = options.classes[strength];

    options.indicator.removeClass(options.indicator.data('pwclass'));

    if (!$(this).val().length) {
      options.indicator.find(options.label).html('');
      klass = 'no-pw';
      options.indicator.data('pwclass', klass);
      options.indicator.addClass(klass);
      return;
    }

    options.indicator.data('pwclass', klass);
    options.indicator.addClass(klass);
    options.indicator.find(options.label).html(options.texts[strength]);
  }

  $.fn.pwstrength = function (options) {
    var options = $.extend(
      {
        label: '.label',
        classes: [
          'pw-very-weak',
          'pw-weak',
          'pw-mediocre',
          'pw-strong',
          'pw-very-strong',
        ],
        texts: ['very weak', 'weak', 'mediocre', 'strong', 'very strong'],
      },
      options || {},
    );
    options.indicator = $(`#${this.data('indicator')}`);

    const klass = 'no-pw';
    options.indicator.data('pwclass', klass);
    options.indicator.addClass(klass);

    return this.on('keyup', options, updateIndicator);
  };
})(jQuery);
