/* eslint-disable */
import $ from 'jquery';
import moment from 'moment';
import dragula from 'dragula';

import vex from 'vex-js';
import vexDialog from 'vex-dialog';
import 'jquery-ui-dist/jquery-ui';
import 'datatables';
import 'jquery-mask-plugin';
import 'malihu-custom-scrollbar-plugin';
import 'jquery-validation';
import 'url-polyfill';

window.moment = moment;
window.dragula = dragula;
window.vex = vex;
vex.registerPlugin(vexDialog);

window.$ = $;
window.jQuery = $;

require('moment/locale/pt');
require('moment/locale/en-gb');
require('./jquery.pwstrength');
require('autonumeric');
require('bootstrap-switch');
require('highcharts');
require('fullcalendar');
require('./formUtils/formUtils');
require('./formUtils/twoSteps');
require('./formUtils/confirmationKey');
require('./formUtils/otpHandler');
require('./formUtils/reports');


$(() => {
  $('.full-layout-area').addClass('login');
  window.lang = $('#language-locale').val();
  window.moment.locale(window.lang);
  moment.locale(window.locale);
});
