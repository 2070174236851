import $ from 'jquery';

window.flagContinueResumeEBPlus = true;

// 2 steps logic
window.resumeDivAddRow = (label, value, isAmount) => {
  const cssAmount = isAmount ? 'money-mask' : '';
  let resumeDivTrasEbPlus =
    '<div class="form-resume-block blockColumnEbPlus arial10EBPlus">';
  resumeDivTrasEbPlus +=
    ' <div class="form-resume-block-label selectLabelEBPlus " >';
  resumeDivTrasEbPlus += label;
  resumeDivTrasEbPlus += ' </div>';
  resumeDivTrasEbPlus += ` <div class="form-resume-block-text inputLabelEBplus arialBold10EBPlus widthResumeEBplus ${cssAmount}">`;

  if (value.indexOf('amoutValue') === -1) {
    /* eslint-disable-next-line */
    value = $('<div/>').text(value).html();
  }
  resumeDivTrasEbPlus += value.length > 0 ? value : ' -'; // encode input value to
  resumeDivTrasEbPlus += ' </div>';
  resumeDivTrasEbPlus += '</div>';

  return resumeDivTrasEbPlus;
};

// 2 steps logic
window.renderResumeColumns = (form) => {
  const COLS = 3;
  const MIN_ROWS = 3;
  const FIELDS_CLASS = '.form-resume-block';
  const fields = form.find(FIELDS_CLASS);
  let rowsPerColumn = Math.ceil(fields.length / COLS);
  rowsPerColumn = rowsPerColumn > MIN_ROWS ? rowsPerColumn : MIN_ROWS;

  fields.each((i, element) => {
    const index = i + 1;
    if (index % rowsPerColumn === 0 || index === fields.length) {
      $(element)
        .prevAll(FIELDS_CLASS)
        .addBack()
        .wrapAll('<div class="column">');
    }
  });
  $('div.eb-row-full > div.column').wrapAll('<div class="eb-row-restricted"/>');
};

// 2 steps router
window.createdResumetransfer = (nameid, transactionCode) => {
  const container = $(`#hidden_form_resume_${transactionCode}`);
  let selectCssEbPResumeName = '';
  let selectCssEbPResumeValue = '';
  let resumeDivTrasEbPlus;
  let i;
  let selectTagNameEBPLus;
  let resumeContainer;

  $('.ads-container').hide(); // hide ads-container
  $(`.resumeContainer${transactionCode}`).addClass(
    'eb-row-full has-padding has-background',
  ); // Element of resume form
  $('.transaction-content').addClass('eb-row-full'); // set transaction content to 100%

  if (window.breadcrumb && window.breadcrumb.addTitle) {
    window.breadcrumb.addTitle(
      window.eb.labels.breadcrumb.breadcrumbResume,
      window.breadcrumb.hasThirdLevel,
    );
  }

  if (transactionCode && transactionCode !== '' && container.length > 0) {
    selectCssEbPResumeName = $('.selectCssEbPResumeName', container);
    selectCssEbPResumeValue = $('.selectCssEbPResumeValue', container);
  } else {
    selectCssEbPResumeName = $('.selectCssEbPResumeName');
    selectCssEbPResumeValue = $('.selectCssEbPResumeValue');
  }

  const selectCssEbPResumeNameLength = selectCssEbPResumeName.length;
  const selectCssEbPResumeValueLength = selectCssEbPResumeValue.length;

  window.flagContinueResumeEBPlus = true;

  if (
    selectCssEbPResumeNameLength === selectCssEbPResumeValueLength &&
    selectCssEbPResumeNameLength !== 0 &&
    selectCssEbPResumeValueLength !== 0
  ) {
    resumeDivTrasEbPlus =
      '<div class="form-resume eb-row-full has-padding has-background resumeDetailsEbPlusTrans resume-component">';

    /* eslint-disable-next-line */
    for (i = 0; i < selectCssEbPResumeNameLength; i++) {
      if (selectCssEbPResumeValue[i]) {
        if (selectCssEbPResumeValue[i].tagName === 'SELECT') {
          selectTagNameEBPLus =
            selectCssEbPResumeValue[i].value !== ''
              ? selectCssEbPResumeValue[i].options[
                  selectCssEbPResumeValue[i].selectedIndex
                ].text
              : '';
          resumeDivTrasEbPlus += window.resumeDivAddRow(
            selectCssEbPResumeName[i].innerHTML,
            selectTagNameEBPLus,
            false,
          );
        } else if (
          $(selectCssEbPResumeValue[i]).hasClass('resume-money-mask')
        ) {
          resumeDivTrasEbPlus += window.resumeDivAddRow(
            selectCssEbPResumeName[i].innerHTML,
            selectCssEbPResumeValue[i].innerHTML,
            true,
          );
        } else {
          resumeDivTrasEbPlus += window.resumeDivAddRow(
            selectCssEbPResumeName[i].innerHTML,
            selectCssEbPResumeValue[i].value,
            false,
          );
        }
      }
    }

    resumeDivTrasEbPlus += '</div>';

    if (
      document.getElementById(`ebPlusID${nameid}Btn`) &&
      document.getElementById(`ebPlusID${nameid}Btn`).outerHTML != null
    ) {
      resumeDivTrasEbPlus += document.getElementById(`ebPlusID${nameid}Btn`)
        .outerHTML;
    } else if (document.getElementById(`ebPlusID${nameid}Btn`)) {
      resumeDivTrasEbPlus += new XMLSerializer().serializeToString(
        document.getElementById(`ebPlusID${nameid}Btn`),
      );
    }

    resumeContainer = document.getElementById(`ebPlusID${nameid}`);
    if (resumeContainer != null)
      resumeContainer.innerHTML = resumeDivTrasEbPlus;
    window.renderResumeColumns($(resumeContainer).find('div.form-resume'));
  }

  // Amounts Components with currency
  $('.selectCssEbPResumeCurrency').each((index, element) => {
    const currency = element.innerHTML;
    $('.resume-transfers-container .money-mask')
      .eq(index)
      .ebAmount()
      .currency(currency);
  });

  // Hide any elements that have this class when transitioning to the resume view
  $('.eb-hide-on-resume').hide();
};

// 2 steps logic
window.backFormInitial = (btnIDResume, nameID) => {
  if (window.breadcrumb && !window.breadcrumb.hasForm) {
    window.breadcrumb.removeTitleLevel();
    window.breadcrumb.addTitle(window.breadcrumb.lastTitle);
  }

  // hide ads-container
  $('.ads-container').show();

  // set transaction content to 100%
  $('.transaction-content').removeClass('full-container');

  window.showTransactionDetailHeader();

  // Show any elements that have this class when transitioning back to the transaction view
  $('.eb-hide-on-resume').show();

  // Hide sucess layout of secondary trans
  $('.secondaryTrans').each(function hideSecondaryTrans() {
    $(this).hide();
  });

  // validation if is expiration and confirmation method is card key
  if (nameID === 'expiration' && window.methodKeyConfirmation === '2') {
    if ($('.cardKeyTableContainerapsa').length > 0) {
      $('#cardKeyTableContainerapsa').prepend($('#cardKeyContainerapsa'));
    }
  }

  window.cleanConfirmationKey(nameID);
  if (document.getElementById('ebPlusIDformID')) {
    document.getElementById('ebPlusIDformID').style.display = '';
  }

  if (typeof window.nameIDEBPLus !== 'undefined') {
    // if exists, show consult and hide ok/error msg
    if (document.getElementById(`ebPlusID${window.nameIDEBPLus}1`) != null) {
      document.getElementById(`ebPlusID${window.nameIDEBPLus}1`).style.display =
        '';

      const elementExist = document.getElementById(
        `ebPlusID${window.nameIDEBPLus}3`,
      );
      const resumeDiv = document.getElementById(
        `ebPlusID${window.nameIDEBPLus}2`,
      );

      if (elementExist) {
        document.getElementById(
          `ebPlusID${window.nameIDEBPLus}3`,
        ).style.display = 'none';

        if (resumeDiv) {
          document.getElementById(
            `ebPlusID${window.nameIDEBPLus}2`,
          ).style.display = 'none';
        }
        // special cases when theres a need to show the first continue button when error occurs
        // since it was necessary to merge step 1 and step 2 in the same view
        if (window.nameIDEBPLus === 'apsa' || window.nameIDEBPLus === 'achc') {
          $('#tabs-personalization').show();
        }
      }

      // special cases when theres a need to show the first continue button when error occurs
      // since it was necessary to merge step 1 and step 2 in the same view
      if (resumeDiv && window.nameIDEBPLus === 'expiration') {
        document.getElementById(`ebPlusIDformID${nameID}`).style.display = '';

        // hidden div of errorCode = C
        if (document.getElementById(`errorSystemEBC${nameID}`)) {
          document.getElementById(`errorSystemEBC${nameID}`).style.display =
            'none';
        }
        document.getElementById(
          `ebPlusID${window.nameIDEBPLus}1`,
        ).style.display = '';
        document.getElementById(
          `ebPlusID${window.nameIDEBPLus}2`,
        ).style.display = 'none';
      }

      if (
        window.nameIDEBPLus === 'expiration' &&
        $('#ebPlusIDformIDexpiration').length
      ) {
        if ($('.expiration-dialog').length) {
          $('.expiration-dialog').show();
        }
        $('#ebPlusIDformIDexpiration').show();
      }
    }
  }
  if (document.getElementById(btnIDResume)) {
    document.getElementById(btnIDResume).style.display = 'none';
  }
  // Personalization transactions require their tabs to be shown
  $('#tabs-bar').show();
  if (nameID === 'adpe')
    $('#tabs-personalization').addClass('eb-row-restricted');

  // Hides the final layout of the success/fail
  if (document.getElementById('operationStatusFailure')) {
    document.getElementById('operationStatusFailure').style.display = 'none';
  }
  const elementExist = document.getElementById('operationStatusSuccessful');
  if (elementExist) {
    document.getElementById('operationStatusSuccessful').style.display = 'none';
  }

  window.cardComponent(null, nameID);

  $('#confirmationContainer').removeClass('confirmation-layout');
  $('#confirmationContainer').height(0);

  if ($('#tabs-personalization').length) {
    $('#tabs-personalization').addClass('eb-row-restricted');
  }
};

// 2 steps logic
window.backForm = (nameID) => {
  // show ads-container
  $('.ads-container').show();

  // Remove element of resume form
  $(`.resumeContainer${nameID}`).removeClass(
    'eb-row-full has-padding has-background',
  );
  $(`.resumeContainer${nameID}`).height(0);

  // Remove element of resume form
  $('#confirmationContainer').removeClass('confirmation-layout');
  $('#confirmationContainer').height(0);

  // Clean report fields
  $('.form-resume').removeClass('resumeDetailsEbPlusTrans');

  window.showTransactionDetailHeader();

  // Show any elements that have this class when transitioning back to the transaction view
  $('.eb-hide-on-resume').show();

  // set transaction content back to 70%
  $('.transaction-content').removeClass('full-container');

  document.getElementById(`ebPlusID${nameID}2`).style.display = 'none';

  const elementExist = document.getElementById(`ebPlusID${nameID}1`);

  if (elementExist) {
    document.getElementById(`ebPlusID${nameID}1`).style.display = '';
  } else {
    document.getElementById(`ebPlusID${window.nameIDEBPLus}1`).style.display =
      '';
  }

  if (window.breadcrumb && !window.breadcrumb.hasForm) {
    window.breadcrumb.removeTitleLevel();
    window.breadcrumb.addTitle(window.breadcrumb.lastTitle);
  }

  // Remove 3º level
  if (window.breadcrumb && window.breadcrumb.hasThirdLevel) {
    window.breadcrumb.removeTitleLevel();
  }

  window.cardComponent(null, nameID);
};

// check the 2 steps
window.contineEfect = (nameID, isFirst, operationID, numberMaxDigit) => {
  // Remove element of resume form
  $(`.resumeContainer${nameID}`).removeClass(
    'eb-row-full has-padding has-background',
  );
  $(`.resumeContainer${nameID}`).height(0);

  /* eslint-disable-next-line */
  const _btnConfirmBackKey = document.getElementById(
    `btnConfirmBackKey${nameID}`,
  );
  const elementExist = document.getElementById(`ebPlusID${nameID}1`);
  const resumeExist = document.getElementById(`ebPlusID${nameID}2`);
  const keyExist = document.getElementById(`ebPlusID${nameID}key`);
  const sucessExist = document.getElementById(`ebPlusID${nameID}3`);
  const myFormConfirmationKey = $('#ebPlusIDformID').validate();
  if (resumeExist) {
    document.getElementById(`ebPlusID${nameID}2`).style.display = 'none';
  } else if (elementExist) {
    document.getElementById(`ebPlusID${nameID}1`).style.display = 'none';
  } else {
    document.getElementById(`ebPlusID${window.nameIDEBPLus}1`).style.display =
      'none';
    if (window.nameIDEBPLus === 'expiration') {
      document.getElementById(`ebPlusID${window.nameIDEBPLus}2`).style.display =
        'none';
    }
  }

  // special case for screen of confirmation key
  if (keyExist) {
    try {
      window.breadcrumb.removeTitleLevel();
    } catch (e) {
      console.info(
        'The first and second levels of the breadcrumb are fixed => ',
        e,
      );
    }

    let myFormCardKey;

    if (keyExist.style.display === 'none') {
      // if the transaction calls the webservice at addOnLoad,
      // and the transaction has confirmationKey, the first screen is hidden, and initialy display confirmation key the screen
      if (elementExist && elementExist.style.display === '') {
        document.getElementById(`ebPlusID${nameID}1`).style.display = 'none';
      }
    } else if (myFormConfirmationKey.form()) {
      document.getElementById(`ebPlusID${nameID}key`).style.display = 'none';

      // verification if input confirmation Key is not null
      myFormCardKey = false; // Validation form when is Card Coordinates Layout
      if (window.methodKeyConfirmation === window.eb.isConfirmationKey) {
        // Confirmation Key
        /* eslint-disable-next-line */
        for (let i = 1; i <= numberMaxDigit; i++) {
          $(`#FieldValueConfirmation${nameID}${i}`).attr('required', 'true');
          if (window.nameIDEBPLus === 'expiration') {
            $(`#FieldValueConfirmation${i}`).val(
              $(`#FieldValueConfirmation${nameID}${i}`).val(),
            );
          }
        }

        window.formKeyValidate(
          nameID,
          _btnConfirmBackKey,
          elementExist,
          sucessExist,
          isFirst,
          operationID,
        );
      } else {
        window.formKeyNotValidate(keyExist, nameID);
      }
    } else {
      // coordinates card
      const inputs = $('.coordCellInput input');
      inputs.each(function setMyFormCardKey() {
        if ($(this).val() !== '') {
          myFormCardKey = true;
        } else {
          myFormCardKey = false;
        }
      });

      if (myFormCardKey) {
        window.formKeyValidate(
          nameID,
          _btnConfirmBackKey,
          elementExist,
          sucessExist,
          isFirst,
          operationID,
        );
      } else {
        window.formKeyNotValidate(keyExist, nameID);
      }
    }
  } else {
    // end if keyExist
    window.breadcrumb.removeTitleLevel();

    if (myFormConfirmationKey) {
      if (myFormConfirmationKey.form()) {
        window.formKeyValidate(
          nameID,
          _btnConfirmBackKey,
          elementExist,
          sucessExist,
          isFirst,
          operationID,
        );
      } else {
        window.formKeyNotValidate(keyExist, nameID);
      }
    }
  }
};

// Validate the 2 steps logic
/* eslint-disable-next-line */
window.continueForm = (nameID, values) => {
  // clean hidden input to otp code
  $('#codeOPT').val('');

  // clean confirmation key
  window.cleanConfirmationKey(nameID);

  const formElement = $('#ebPlusIDformID').validate();

  // Validation if form is Valid
  if (formElement.form()) {
    window.eb.post(
      window.simGenericoLink,
      $.extend(
        {
          operationId: nameID,
          _isFirst: 'isBackgroundTransactionStep2',
        },
        values ? { data: values } : {},
      ),
    );
  } else {
    return false;
  }
};
