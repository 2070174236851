import $ from 'jquery';

// Check the Otp logic
window.contineOTP = (simGenericoLinkOTP, isFirst, operationID) => {
  const formElement = !$('.EB_ExpirationDialog').length
    ? $('#ebPlusIDformID').validate()
    : $(window.expirationDialog).find('form').validate();

  // Validation if form is Valid
  if (formElement.form()) {
    if ($('#EBPlusExpirationDialog').length) {
      $('#optEBplusexpiration').show();
    } else {
      $('#optEBplus').hide();
    }

    window.eb
      .post(simGenericoLinkOTP, {
        operationId: operationID,
        _isFirst: isFirst,
      })
      .then(window[`processResponseEBPlus_${operationID}`]);
  }
};

// Analyse for the otp logic
window.backFormOTP = () => {
  let nameformSpecial = '';
  if ($('#EBPlusExpirationDialog').length) {
    nameformSpecial = 'expiration';
  }

  // Remove element of resume form
  $('#confirmationContainer').removeClass('confirmation-layout');
  $('#confirmationContainer').height(0);

  // hidden div to OPT interface
  $(`#optEBplus${nameformSpecial}`).hide();

  if (nameformSpecial === 'expiration') {
    setTimeout(window.eb.predefinedAjax.logout, 0);
  } else {
    // show cancel OTP operation
    window.eb.failedTransactionOverlay
      .setTransactionCode(nameformSpecial)
      .setErrorCode('X')
      .setStatusTitle(window.eb.labels.operationStatus.cancelOTP)
      .cleanErrors()
      .open();
  }
};

// Analyse for the otp logic
window.backFormInitialOTP = (btnIDResume, nameID) => {
  let nameFormSpecial = '';

  window.showTransactionDetailHeader();
  // Show any elements that have this class when transitioning back to the transaction view
  $('.eb-hide-on-resume').show();

  if (nameID === 'expiration') {
    nameFormSpecial = nameID;

    // validation if is expiration and confirmation method is card key
    if (window.methodKeyConfirmation === '2') {
      if ($('.cardKeyTableContainerapsa').length > 0) {
        $('#cardKeyTableContainerapsa').prepend($('#cardKeyContainerapsa'));
      }
    }
  }

  $(`#codeOPT${nameFormSpecial}`).val('');
  $(`#ebPlusIDformID${nameFormSpecial}`).show();
  $(`#errorSystemEBC${nameFormSpecial}`).hide();
  $(`#optEBplus${nameFormSpecial}`).show();
};

// pass into otp module
window.otpStep2 = (nameTrans, statusCode) => {
  const formElement = $('#ebPlusIDformID').validate();
  $('#statusS').val('S');
  if (window.nameIDEBPLus === 'expiration') window.bindJumpNextKey();

  try {
    window.breadcrumb.removeTitleLevel();
  } catch (e) {
    console.info(
      'The first and second levels of the breadcrumb are fixed => ',
      e,
    );
  }

  // Validation if form is Valid
  if (formElement.form()) {
    // Remove element of resume form
    $(`.resumeContainer${nameTrans}`).removeClass(
      'eb-row-full has-padding has-background',
    );
    $(`.resumeContainer${nameTrans}`).height(0);
    $('#displayedStatusCodeScreen').val('S');

    if (document.getElementById(`ebPlusID${nameTrans}key`)) {
      document.getElementById(`ebPlusID${nameTrans}key`).style.display = 'none';
    }

    window.hideProductHeader();

    /** ***********************************************************************
       * statusCode = Q or C - key  and  statusCode = R - card
      /************************************************************************ */
    if (statusCode === 'Q' || statusCode === 'R' || statusCode === 'C') {
      if (document.getElementById(`ebPlusID${nameTrans}2`)) {
        document.getElementById(`ebPlusID${nameTrans}2`).style.display = 'none'; // hidde resume
      }

      $(`#ebPlusID${nameTrans}key`).show();

      $('.transaction-header-details').hide();

      const parentDivResume = `ebPlusID${nameTrans}2`;
      const parentDivKey = `ebPlusID${nameTrans}key`;
      const allElementKey = $(`#${parentDivKey} .toStepKey2`);
      const allElementResume = $(`#${parentDivResume} .toStep2`);
      const myQueryKeyQ = $(`#${parentDivKey} #btnQ-step2-key`);
      const myQueryResume = $(`#${parentDivResume} #btnQ-step2-resume`);

      if ($(allElementResume).length > 0) {
        $(allElementResume).hide();
      }

      $(allElementKey).hide();

      if ($(myQueryResume).length > 0) {
        $(myQueryResume).show();
      }

      // validation if confirmation key
      if (window.methodKeyConfirmation === window.eb.isConfirmationKey) {
        $(myQueryKeyQ).show();
      } else {
        $(myQueryKeyQ).each((index, node) => {
          // remove this class if exist
          $(node).removeClass('btn-card-key');

          // added class of element btn
          $(node).addClass('btn-card-key');
        });

        $(myQueryKeyQ).hide();
      }
    }
  }
};

// pass into otp module
window.showOTP = (nameTrans, status) => {
  // Remove element of resume form
  $(`.resumeContainer${nameTrans}`).removeClass(
    'eb-row-full has-padding has-background',
  );
  $(`.resumeContainer${nameTrans}`).height(0);

  const formElement = !$('.EB_ExpirationDialog').length
    ? $('#ebPlusIDformID').validate()
    : $(window.expirationDialog).find('form').validate();
  $('#statusS').val('S');
  // Validation if form is Valid
  if (formElement.form()) {
    const coreLogKey = document.getElementById('hidden_coreLogKey').value; // get coreLog Key
    const statusD = $('#statusD').val() !== '' ? $('#statusD').val() : '';
    $('#displayedStatusCodeScreen').val('S');

    window.eb.resendTimer(window.eb.smsTimerOTP);

    if (status !== 'O') {
      // Expiration
      if ($('.EB_ExpirationDialog').length > 0) {
        /* eslint-disable-next-line */
        for (let i = 1; i <= window.numberMaxDigit; i++) {
          $(`#FieldValueConfirmation${nameTrans}${i}`).attr('required', 'true');
          $(`#FieldValConfirmation${nameTrans}${i}`).val(
            $(`#FieldValueConfirmation${nameTrans}${i}`).val(),
          );
        }
      }

      const callBackParameter = { nameTrans };

      if (statusD !== 'D') {
        if (formElement.form()) {
          window.eb
            .post('receiveParameters', {
              data: {
                typeTransaction: 'MWCONF',
                parameters: JSON.stringify({
                  MWCONF_I_0001: coreLogKey,
                  MWCONF_I_0002: 'O',
                  MWCONF_I_0004: 'SMS',
                  MWCONF_I_0005: 'N',
                }),
              },
            })
            .then((response) => {
              window.processResponseEBPlus_reloadMWCONF(
                response,
                callBackParameter,
              );
            });
          if (document.getElementById('hidden_isOTP'))
            document.getElementById('hidden_isOTP').value = 'false';
        } else {
          window.eb.dialog.alert(window.eb.labels.messages.fomIsValid);
        }
      } else {
        window.processResponseEBPlus_reloadMWCONF('', callBackParameter);
      }
    } else {
      try {
        window.breadcrumb.removeTitleLevel();
      } catch (e) {
        console.info(
          'The first and second levels of the breadcrumb are fixed => ',
          e,
        );
      }
      const callBackParameter = { nameTrans };

      window.eb
        .post('receiveParameters', {
          data: {
            typeTransaction: 'MWCONF',
            parameters: JSON.stringify({
              MWCONF_I_0001: coreLogKey,
              MWCONF_I_0002: 'O',
              MWCONF_I_0004: 'SMS',
              MWCONF_I_0005: 'N',
            }),
          },
          _isFirst: 'isBackgroundTransaction',
        })
        .then((response) => {
          window.processResponseEBPlus_reloadMWCONF(
            response,
            callBackParameter,
          );
        });

      if (document.getElementById('hidden_isOTP'))
        document.getElementById('hidden_isOTP').value = 'false';

      const confirmationExist = $(`#ebPlusID${nameTrans}key`);
      const resumeExist = $(`#ebPlusID${nameTrans}2`);

      if ($(confirmationExist).length > 0) {
        $(confirmationExist).hide();
      }

      if ($(resumeExist).length > 0) {
        $(resumeExist).hide();
      }

      // hidden interface and show logic otp
      document.getElementById('optEBplus').style.display = '';
      if (document.getElementById('hidden_isOTP'))
        document.getElementById('hidden_isOTP').value = 'true';
    }
  }
};

// pass into otp module
window.processResponseEBPlus_cotp = (response, _isFirst) => {
  if (response.operationData && response.operationData.COTP_O_0001) {
    // Get transaction code
    let transactionCode = response.operationData.COTP_O_0001;
    transactionCode = transactionCode.toLowerCase();

    if((transactionCode !== nameIDEBPLus) && (/^mw/.test(nameIDEBPLus)))
    transactionCode = nameIDEBPLus
    
    $(`ebPlusID${window.nameIDEBPLus}3`).show();

    // Redirect to respective ttr
    window[`processResponseEBPlus_${transactionCode}`](
      response,
      _isFirst,
      '',
      false,
    );
  }
};

window.processResponseEBPlus_reloadMWCONF = (response, callBackParameter) => {
  const nameTransaction = callBackParameter.nameTrans;
  const confirmationExist = $(`#ebPlusID${nameTransaction}key`);
  const resumeExist = $(`#ebPlusID${nameTransaction}2`);

  if (confirmationExist.length > 0) {
    $(confirmationExist).hide();
  }

  if (resumeExist.length > 0) {
    $(resumeExist).hide();
  }

  // hidden interface and show logic otp

  if ($('#EBPlusExpirationDialog').length) {
    window.nameformSpecial = 'expiration';
    $(`#optEBplus${window.nameformSpecial}`).show();
  } else {
    document.getElementById('optEBplus').style.display = '';
  }
};

/**
 * Bind and focus the next confirmation input
 *
 */
window.bindJumpNextKey = () => {
  $(function () {
    $('.inputConfirmationKey').on('keyup', function () {
      if (this.value.length === this.maxLength) {
        $(this).parent().next().find('.inputConfirmationKey').trigger('focus');
      }
    });
  });
};
