// Used in 2 places
window.reportCall = (typeFile, transactionCode) => {
  const timestamp = new Date().getTime();

  // typeFile = (PDF, DOCX, XLS, XLSX, HTML)
  window[`createStructureReport_${transactionCode.toUpperCase()}`](
    typeFile,
    transactionCode,
    timestamp,
  );
};

// Pass into reports module
window.setStructureReport = (_structureReport) => {
  window.structureReport = _structureReport;
};

// Pass into reports module
window.getStructureReport = () => {
  return window.structureReport;
};

// To remove and use the eb.predefinedAjax.exportFiles
window.ajaxReportingCall = (
  transactionCode,
  transactionParameters,
  _reportInfo,
  timeStampExport,
  typeFile,
) => {
  const parameters = {
    typeTransaction: transactionCode,
    parameters: transactionParameters,
    parameterReportInfo: _reportInfo,
    parameterTimeStampExport: timeStampExport,
    parameterTypeFile: typeFile,
    fileReportEncoding:
      navigator.userAgent.match(/Firefox/i) == null ? 'true' : 'false', // validation all browser force enconding except firefox
  };

  window.eb.predefinedAjax.exportFiles('reportfrommiddleware', {
    operationId: 'reporting',
    data: parameters,
    _isFirst: 'isFirst',
  });
};
